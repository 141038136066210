<div fxLayout="column" fxLayoutAlign="space-between stretch" class="wrapper">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

    <div fxFlex=“33” class="cardWrapper">
      <mat-card *ngIf="event" class="detailsCard">
        <mat-card-title>{{ event.name }}</mat-card-title>
        <mat-card-subtitle>{{ event.location?.place?.formattedAddress }}</mat-card-subtitle>
        <mat-card-subtitle>{{ event.startDateTime }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="event.salesStartDateTime">Ticket
          sales: {{ event.salesStartDateTime }}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="storefronts">
          <div *ngFor="let storefront of this.storefronts">
            <a href="{{this.getStorefrontURL(storefront)}}">{{ this.getStorefrontURL(storefront) }}</a>

          </div>
        </mat-card-subtitle>

        <mat-card-subtitle *ngIf="eventContacts">
          <ul *ngFor="let contact of this.eventContacts">
            <li>{{ contact.contact }} - {{ contact.source }}</li>
          </ul>
        </mat-card-subtitle>


      </mat-card>
    </div>

    <div fxFlex=“33” class="cardWrapper">
      <mat-card *ngIf="eventStats" class="statsCard">
        <mat-card-title>Sales Summary</mat-card-title>
        <mat-card-subtitle>Total Orders - {{ eventStats.totalOrders }}</mat-card-subtitle>
        <mat-card-subtitle>Total Revenue - {{ eventStats.totalRevenue|currency: event.eventCurrency }}
        </mat-card-subtitle>
        <mat-card-subtitle>Total Tickets - {{ eventStats.ticketsSold }}</mat-card-subtitle>

        <div *ngIf="plans && plans.length > 0">
          <mat-form-field>
            <mat-label>Plan</mat-label>
            <mat-select [(value)]="eventMeta.plan == null ? 'v1':eventMeta.plan">
              <mat-option *ngFor="let plan of this.plans" [value]="plan">
                {{ plan }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button color="primary" (click)="savePlan()">Save</button>
        </div>

      </mat-card>
    </div>

    <div fxFlex=“33” class="cardWrapper">
      <mat-card class="statsCard">
        <mat-card-title>Ticket Details</mat-card-title>
        <mat-card-subtitle *ngFor="let ticketType of this.event.ticketTypes">
          {{ ticketType.name }} - {{ this.ticketTypes.get(ticketType.id.toString()) }}
          - {{ ticketType.indicativeAvailability }}
        </mat-card-subtitle>
        <button mat-stroked-button color="primary" (click)="recalculate()">Recalculate</button>
        <button mat-stroked-button color="primary" (click)="reindexOrders()">Reindex Orders</button>
        <button mat-stroked-button color="primary" (click)="reindexTickets()">Reindex Tickets</button>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
       *ngIf="this.lastTickets && this.ticketTypes">
    <!--    <div fxFlex=“50”>-->
    <!--      <mat-card>-->
    <!--        <app-checkin-chart [checkinStats]="this.checkinStats" #checkinChart-->
    <!--                           [event]="this.event" [ticketTypeSold]="this.ticketTypes"></app-checkin-chart>-->
    <!--      </mat-card>-->
    <!--    </div>-->
    <div fxFlex=“50” class="nonCard">
      <app-ticket-list [tickets]="this.lastTickets"></app-ticket-list>
    </div>
  </div>

  <app-fraud-prevention-list [eventID]="event.id"></app-fraud-prevention-list>

  <div>
    <mat-card>
      <mat-card-title>
        <mat-button-toggle-group name="salesBin" [(ngModel)]="salesBin" (change)="runSalesReport()">
          <mat-button-toggle value="H24">Last 24hrs</mat-button-toggle>
          <mat-button-toggle value="YESTERDAY">Yesterday</mat-button-toggle>
          <mat-button-toggle value="D7">Last 7 days</mat-button-toggle>
          <mat-button-toggle value="MTD">Month to Date</mat-button-toggle>
          <mat-button-toggle value="LASTMONTH">Last Month</mat-button-toggle>
          <mat-button-toggle value="D90">Last 90 days</mat-button-toggle>
          <mat-button-toggle value="YEAR">Last Year</mat-button-toggle>
          <mat-button-toggle value="ALL">All</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-card-title>
      <google-chart #salesChart [type]="lineChart" [data]="this.salesData"
                    [options]="chartOptions"
                    class="salesChart"
                    *ngIf="this.salesData"></google-chart>
    </mat-card>
  </div>


  <mat-card>
    <google-chart #ticketTypeChart [type]="bar" [data]="this.myData"
                  [options]="chartOptions"
                  [options]="{isStacked:true}"></google-chart>
  </mat-card>

  <mat-card *ngIf="checkinStats" class="statsCard">
    <mat-card-title>Total Checkins</mat-card-title>
    <mat-card-subtitle>
      {{ totalCheckins }}
    </mat-card-subtitle>

  </mat-card>
</div>

