import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Event, EventContact, EventMeta, EventStats, Storefront, Ticket} from "../model/model";
import {AdminService} from "../admin.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {ChartType, Column, GoogleChartComponent, Row} from "angular-google-charts";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CheckinChartComponent} from "../checkin-chart/checkin-chart.component";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  constructor(public route: ActivatedRoute, public adminService: AdminService, public db: AngularFireDatabase,
              private snackBar: MatSnackBar) {
  }

  audio = new Audio();

  event: Event;
  eventStats: EventStats;
  eventMeta: EventMeta;
  lastTickets: Ticket[];
  eventContacts: EventContact[];
  plans: string[];
  checkinStats: Map<string, number>;
  ticketTypes: Map<string, number>;
  bar = ChartType.ColumnChart;
  lineChart = ChartType.LineChart;
  myData: Row[] = []
  totalCheckins = 0;
  salesData: any[][];
  salesColumns: Column[];
  salesBin: string = 'H24';
  storefronts: Storefront[];
  @ViewChild(GoogleChartComponent) ticketTypeChart!: GoogleChartComponent;
  @ViewChild(CheckinChartComponent) checkinChart!: CheckinChartComponent;

  chartOptions = {
    colors: ['#5CDB95', '#05386B'],
    legend: {position: 'none'},
    curveType: 'function',
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: {axis: 'tickets'},
      1: {axis: 'sales'}
    },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    minValue: 0,
    vAxis: {viewWindow: {min: 0}},
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        tickets: {label: 'Value of sales'},
        sales: {label: 'Tickets sold'}
      }
    }
  };

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get('id')!;
    this.audio.src = "../../assets/checkin.mp3";
    this.audio.load();
    this.adminService.getEvent(id).subscribe((value: Event) => {
      this.doLoad(value);
    });

  }


  doLoad(event: Event) {
    this.event = event;

    this.adminService.getEventStorefronts(event.id).subscribe((value: Storefront[]) => {
      this.storefronts = value;
    });

    this.adminService.getEventStats(event.id).subscribe(value => {
      this.processEventStats(value);
    });

    this.adminService.eventContacts(event.id).subscribe(value => {
      this.eventContacts = value;
    });
    this.adminService.getEventMeta(event.id).subscribe(value => {
      this.eventMeta = value;
    });

    this.adminService.getPlans().subscribe(value => {
      this.plans = value;
    });

    this.runSalesReport();
    this.loadLastTickets(this.event.id);
    try {
      this.db.object<EventStats>('eventStats/' + this.event.organization + '/' + this.event.id)
        .valueChanges().subscribe(eventStats => {
        console.log('we just had a change!! ' + eventStats)
        this.processEventStats(eventStats as EventStats);
        this.processTicketTypes(this.ticketTypes);
        this.loadLastTickets(this.event.id);
        this.playAudio();
      });
    } catch (e) {
      console.log(e);
    }


    try {


      this.db.object<Map<string, number>>('checkinStats/' + this.event.organization + '/' + this.event.id)
        .valueChanges().subscribe(checkinStats => {

        if (checkinStats != null) {
          console.log('checkinStats: ' + checkinStats);
          console.log(checkinStats);

          this.checkinStats = new Map(Object.entries(checkinStats));
          this.processCheckinStats(this.checkinStats);
          this.showSnackBar("We have a checkin!");
          // if (this.checkinChart) {
          //   this.checkinChart.createChartData(this.event, this.ticketTypes, this.checkinStats);
          // }
          this.loadLastTickets(this.event.id);

        }
      });


      // this.db.object<Map<string, number>>('checkinStats/' + this.event.organization + '/' + this.event.id)
      //   .valueChanges().subscribe((checkinStats: { [s: string]: number; }) => {
      //
      //   if (checkinStats != null) {
      //     this.checkinStats = new Map(Object.entries(checkinStats));
      //     this.processCheckinStats(this.checkinStats);
      //     this.showSnackBar("We have a checkin!");
      //     // if (this.checkinChart) {
      //     //   this.checkinChart.createChartData(this.event, this.ticketTypes, this.checkinStats);
      //     // }
      //     this.loadLastTickets(this.event.id);
      //   }
      // });
    } catch (e) {
      console.log(e);
    }

  }

  processCheckinStats(checkinStats: Map<string, number>) {
    let total = 0;


    checkinStats.forEach(value => {
      total = total + value;

    })
    this.totalCheckins = total;

  }


  processEventStats(eventStats: EventStats) {
    if (eventStats != null) {
      this.eventStats = eventStats as EventStats;
      this.ticketTypes = new Map(Object.entries(eventStats.ticketTypeQty));

    }

  }

  processTicketTypes(ticketTypes: Map<string, number>) {
    let data: Row[] = [];


    this.event.ticketTypes.forEach(tt => {
      let sold = 0;
      sold = ticketTypes.get(tt.id.toString())!.valueOf();

      let row = [tt.name, tt.quantity, sold];
      data.push(row);
    });

    this.myData = Object.assign([], data)
    // this.myData = data;

    this.ticketTypeChart.data = this.myData;
  }

  runSalesReport() {
    this.adminService.getEventChartData(this.event.id, this.salesBin).subscribe(value1 => {
      this.salesData = value1;


      // this.interpolate(this.salesBin, value1);
      // console.log('sales data: ');
      // console.log(this.salesData);
    });
  }

  interpolate(bin: string, salesData: any[][]) {
    salesData.forEach(value => {
      console.log(value[0]);

    })
  }

  loadLastTickets(event: number) {
    this.adminService.getLastTickets(event).subscribe(value => {
      this.lastTickets = value;
    })
  }


  showSnackBar(msg: string) {
    this.snackBar.open(msg, 'Dismiss', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,

    });

    this.playAudio();
  }

  recalculate() {
    this.adminService.recalculate(this.event.id).subscribe(value => {
      this.doLoad(value);
    })
  }

  reindexOrders() {
    this.adminService.reindexOrders(this.event.id).subscribe(value => {

    })
  }

  reindexTickets() {
    this.adminService.reindexTickets(this.event.id).subscribe(value => {

    })
  }

  savePlan() {
    this.adminService.setPlan(this.event.id, this.eventMeta.plan).subscribe(value => {
    })
  }

  playAudio() {

    this.audio.play()
  }

  getStorefrontURL(storefront: Storefront): string {
    return environment.shopBase + '/' + storefront.id.toString(36);
  }

}
